import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Link = styled.a`
  &&& {
    color: white !important;

    &:hover {
      color: white !important;
    }
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  &&& {
    width: 3rem;
  }
`;

import React, { useState, useRef } from 'react';

import {
  faMapMarker,
  faPhone,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import {
  faInstagram,
  faTwitter,
  faSnapchat,
  faFacebook,
  faYoutube,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

import { HeaderText } from '../HeaderText';
import { SocialLink } from './components/SocialLink/SocialLink';

import { Content, Icon, Form, Social } from './styles';

export const Footer: React.FC = () => {
  const [error, setError] = useState<string>('');
  const emailRef = useRef<HTMLInputElement>(null);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (emailRef.current?.value.trim().length === 0) {
      setError('You must provide an email address.');
      return;
    }

    e.currentTarget.submit();
  };

  return (
    <Content className="footer">
      <div className="container">
        <div className="columns">
          <div className="column">
            <HeaderText color="white" size={4} align="left">
              Contact the Dingo
            </HeaderText>
            <div>
              If you’re really dying to get a hold of Engelo immediately, try a
              few of these good old fashioned ways.{' '}
            </div>
            <br />
            <div>
              <Icon icon={faMapMarker} /> 2428 West Sylvania Avenue Toledo, OH
              43613
            </div>
            <div>
              <Icon icon={faPhone} /> (844) 289-8876
            </div>
            <div>
              <Icon icon={faEnvelope} /> admin@engelorumora.com
            </div>
          </div>
          <div className="column">
            <HeaderText color="white" size={4} align="left">
              Want more?
            </HeaderText>
            <div>
              Sign up below to receive real estate updates, useful tips, and
              other valuable resources straight to your inbox.
            </div>
            <Form
              name="contact"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
              onSubmit={handleFormSubmit}
              action="/submit-success"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <div className="field">
                <label className="label has-text-white">Email Address *</label>
                <div className="control">
                  <input
                    type="email"
                    name="email"
                    className="input"
                    ref={emailRef}
                  />
                </div>
                {error && <p className="help">{error}</p>}
              </div>
              <div className="field">
                <div className="control">
                  <button
                    type="submit"
                    className="button is-primary is-link is-uppercase is-fullwidth"
                  >
                    Sign me up
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <Social className="has-text-centered is-size-5">
          <SocialLink
            icon={faSnapchat}
            href="https://www.snapchat.com/add/engelorumora"
          />
          <SocialLink
            icon={faFacebook}
            href="https://www.facebook.com/RealEstateDingo"
          />
          <SocialLink
            icon={faYoutube}
            href="https://www.youtube.com/channel/UCf4q3NeVFYHa0IWkiufXIvA"
          />
          <SocialLink
            icon={faTwitter}
            href="https://twitter.com/RealEstateDingo"
          />
          <SocialLink
            icon={faInstagram}
            href="https://www.instagram.com/realestatedingo"
          />
          <SocialLink
            icon={faLinkedin}
            href="https://www.linkedin.com/in/engelorumora"
          />
        </Social>
      </div>
      <div className="has-text-centered is-size-6 has-text-white">
        © {new Date().getFullYear()}, Engelo Rumora. All rights reserved.
      </div>
    </Content>
  );
};

import React from 'react';
import { Content } from './styles';

interface IHeaderText {
  color: 'white' | 'black' | 'primary';
  size?: 1 | 2 | 3 | 4 | 5 | 6;
  align?: 'centered' | 'left' | 'right';
  className?: string;
}

export const HeaderText: React.FC<IHeaderText> = ({
  children,
  color,
  size = 2,
  align = 'centered',
  className = '',
}) => (
  <Content
    className={`has-text-${align} title is-${size} is-size-${size +
      1}-mobile is-uppercase has-text-${color} ${className}`}
  >
    {children}
  </Content>
);

import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Content = styled.footer`
  padding-top: 12rem;
  padding-bottom: 12rem;
  &&& {
    background-color: black;
    color: white;

    a {
      color: black !important;
    }
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  &&& {
    width: 2rem;
  }
`;

export const Form = styled.form`
  margin-top: 1rem;
`;

export const Social = styled.div`
  margin-top: 1rem;
`;

import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Icon, Link } from './styles';

interface ISocialLink {
  icon: IconProp;
  href: string;
}

export const SocialLink: React.FC<ISocialLink> = ({ icon, href }) => (
  <>
    <Link
      href={href}
      className="has-text-white"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Icon icon={icon} />
    </Link>
  </>
);
